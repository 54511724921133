import React from 'react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import './App.css';
//import Home from './components/Home';
import Products from './components/Products';
import Gallery from './components/Gallery';
import Companylicenses from './components/Companylicenses';
import Business from './components/Business';
import Agriculturalproducts from './components/Agriculturalproducts';
function Layout() {
  return (
    
      <div className="container">
        {/* Header Section */}
        <header className="header">
          <img src="/logo.png" alt="AntarVeda Agros Logo" className="logo" />
          <a href ="/agriculturalproducts" target='_blank' rel ="nopoener noreferrer">
          <button className="agricultural-products-btn">
                Agricultural Products
              </button>
              </a>
              <a href ="/business" target='_blank' rel ="nopoener noreferrer">
              <button className ="business-partners-btn">
                Business Partners
              </button>
              </a>
              <a href ="/companylicenses" target='_blank' rel ="nopoener noreferrer">
              
              <button className="company-licenses-btn">
                Company Licenses
              </button>
              </a>
               {/* Gallery button that opens in a new tab */}
               <a href ="/gallery" target='_blank' rel ="nopoener noreferrer">
              {/*<Link to="/gallery">*/}
              <button className="gallery-btn">
              {/*  onClick={() => Navigate('/gallery')}>*/}
                Gallery
              </button>
              </a>
              {/*</Link>*/}
          <h1 className="header-title">Growing Together for a Better Tomorrow</h1>
          <p className="header-subtitle">Your trusted partner for premium agricultural products.</p>
        </header>

        {/* Hero Image Section */}
        <section className="hero-image">
          <img src="/cocoa_farm_centre.png" alt="Cocoa Farm Centre" className="cocoa_farm_centre-img" />
          <img src="/mango_farm_7.png" alt="mango_farm_7" className="mango_farm_7-img" />
          <div className="overlay"></div>
          </section>
          <h2 className="hero-heading">Transforming Agriculture with Agro Commodity Trading</h2>
        

        {/* Main Content */}
        <main className="main-content">
          {/* Introduction Section */}
          <section className="intro">
            <h2>About Us</h2>
            <p>AAPL is an agri-commodity start-up company founded in 2022, based in Eluru, Andhra Pradesh, India. AAPL works
              with farmers in the Eluru district to source Cocoa Beans and Mangoes directly from producer farmers and FPOs.
            </p>
            <p>AAPL sources Dry Cocoa Beans and Farm Fresh Mangoes, conducting primary processing such as grading and
              cleaning to maintain quality standards before supplying these products to corporate firms and processing
              factories. AAPL has embarked upon a B2B trade and marketing model.</p>
            <p>AntarVeda Agros is committed to delivering the highest quality agricultural products to farmers and
              consumers. Our mission is to innovate and sustainably grow with our community while supporting the
              agricultural industry.</p>
          </section>

          {/* Vision Section */}
          <section className="vision">
            <h2>Vision</h2>
            <p>Sustaining Cocoa and Mango farming by offering fair and best prices to farmers through market access.</p>
          </section>

          {/* Mission Section */}
          <section className="mission">
            <h2>Mission</h2>
            <p>Create access to markets and marketplaces for Cocoa Beans and Mangoes.</p>
          </section>

          {/* Business Strategy Section */}
          <section className="business-strategy">
            <h2>Business Strategy</h2>
            <ul>
              <li>B2B Business model with objective to offer best prices to farmers and quality products to companies / buyers.</li>
            </ul>
          </section>

          {/* Activities Section */}
          <section className="activities">
            <h2>Our Activities</h2>
            <ul>
              <li>Sourcing Agri-commodities – Cocoa Beans, Acid Lemon, Banana, and Mangoes directly from farmers</li>
              <li>Primary Processing (Drying, Cleaning, Grading, Storing, Packing)</li>
              <li>Quality Testing and Monitoring</li>
              <li>Godown/Storage facility</li>
              <li>Transportation logistics arrangement</li>
              <li>Company Insurance</li>
              <li>Sales and marketing</li>
            </ul>
          </section>

          {/* Products Section */}
          <section className="products-section">
            <h2>Products</h2>
            <ul>
              <li>Cocoa beans: Fermented & Dried Cocoa beans</li>
              <li>Mangoes: Farm fresh Banganapalli (safeda) and totapuri.</li>
            </ul>
          {/* <Link to="/products">
              <button className="mt-4 bg-black text-white px-4 py-2 rounded">
                Learn More
              </button>
            </Link>*/}
          </section>

          {/* Contact Information */}
          <section className="contact">
            <h2>Contact Us</h2>
            <p>Email: antarvedaagros@gmail.com</p>
            <p>Phone: +91 9676308587</p>
            <ul>
              <li>D.No. 2-52,</li>
              <li>High School Road, Near Ramalayam</li>
              <li>Sanivarapupeta</li>
              <li>Eluru – 534 003</li>
              <li>Eluru District,</li>
              <li>Andhra Pradesh, India</li>
            </ul>
          </section>
        </main>

        {/* Footer */}
        <footer className="footer">
          <p>&copy; {new Date().getFullYear()} AntarVeda Agros. All rights reserved.</p>
        </footer>
      </div>
  );
}
function App(){
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Layout />} />
        <Route path="/" element={<Products />} />
        {/* Route for the gallery without the full layout */}
        <Route path ="/gallery" element={<GalleryOnly/>}/>{/*Gallery route */}
        <Route path ="/companylicenses" element={<CompanylicensesOnly/>}/>
        <Route path ="/business" element={<BusinessOnly/>}/>{/*companylicenses route */}
        <Route path ="/Agriculturalproducts" element={<AgriculturalproductsOnly/>}/>
      </Routes>
    </Router>
  );
}
// GalleryOnly Component: This will only render the gallery without the header, footer, etc.
function GalleryOnly() {
  return (
    <div className="gallery-container">
      <Gallery />
    </div>
  );
}

// CompanylicensesOnly Component: This will only render the company licenses without the header, footer, etc.
function CompanylicensesOnly() {
  return (
    <div className="companylicenses-container">
      <Companylicenses />
    </div>
  );
}
function BusinessOnly() {
  return (
    <div className="business-container">
      <Business />
    </div>
  );
}
function AgriculturalproductsOnly() {
  return (
    <div className="agriculturalproducts-container">
      <Agriculturalproducts />
    </div>
  );
}

export default App;